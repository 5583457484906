<template>
  <div>
    <div class="mb-6">
      <a
        class="text-decoration-none text-subtitle-2 font-weight-bold"
        @click.prevent="returnBack"
      >
        <v-icon
          color="primary"
          dense
          class="mr-2"
        >
          {{ icons.mdiArrowLeft }}
        </v-icon>
        Kembali
      </a>
    </div>
    <div class="mb-4">
      <v-card class="pa-6 mx-0">
        <v-row class="justify-space-between align-center">
          <v-col
            md="6"
            xl="6"
            cols="6"
            sm="12"
          >
            <h2>Statistik {{ this.spod_id }}</h2>
            <small style="white-space: normal;">Halaman untuk melihat statistik semua Titik Spod</small>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <v-row class="mb-4 mt-5">
      <v-col
        xl="8"
        lg="8"
        md="12"
        sm="12"
        cols="12"
      >
        <v-card>
          <v-card-title>
            <span class="d-block mb-4">Aktifitas Pengunjung</span>
            <v-spacer></v-spacer>
            <div>
              <v-select
                :items="chartYear"
                outlined
                dense
                item-text="label"
                :value="thisYear"
                class="select-year me-1"
                @change="handleSelectChange($event)"
              >
                <!-- <option value=""> -->
                <!-- Select Year -->
                <!-- </option> -->
                <!-- <option -->
                <!-- v-for="(year, i) in getYears" -->
                <!-- :key="i" -->
                <!-- :value="year" -->
                <!-- > -->
                <!-- {{ year }} -->
                <!-- </option> -->
              </v-select>
            </div>
            <div>
              <v-btn
                icon
                small
                class="me-n3 mt-n2"
              >
                <v-icon>
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text>
            <vue-apex-charts
              id="total-profit-chart"
              ref="chart"
              height="320"
              :options="chartOptions"
              :series="chartData"
            ></vue-apex-charts>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        xl="4"
        lg="4"
        md="12"
        sm="12"
        cols="12"
      >
        <v-card>
          <v-card-title>
            <span>Pengunjung</span>
          </v-card-title>
          <v-card-text class="greeting-title">
            <v-row>
              <v-col
                cols="12"
                md="8"
              >
                <v-progress-circular
                  v-if="loadingCircle"
                  class="d-flex align-center flex wrap"
                  indeterminate
                  color="success"
                ></v-progress-circular>
                <div
                  v-else
                  class="d-flex align-center flex wrap"
                >
                  <p class="font-weight-bold text--black mb-0 me-1 text-h6">
                    {{ instance.total_visitors_month }}
                  </p>
                  <p
                    class="text-xs percentage mb-0"
                    :class="instance.total_ratio_visitors_month < 0 ? 'error--text' : 'success--text'"
                  >
                    {{ instance.total_ratio_visitors_month }} %
                  </p>
                </div>
                <v-chip
                  class="v-chip-light-bg info--text mt-3"
                  color="info"
                >
                  Pengunjung {{ getMonthName(date) }}
                </v-chip>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <div>
                  <v-img
                    width="90"
                    src="@/assets/images/3d-characters/pose-m-11.svg"
                    class="gamification-john-pose-2"
                  ></v-img>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="12"
        sm="12"
        cols="12"
      >
        <!-- <v-img src="@/assets/images/demo/buku-dipinjam.png"></v-img> -->
        <v-card class="px-5 py-5">
          <v-card-title class="align-center">
            <span>Buku Yang Paling Banyak Dipinjam</span>
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="me-n3 mt-n2"
            >
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div class="d-flex justify-space-between align-center">
              <p class="d-block my-2 font-weight-bold text-uppercase text-xs">
                Nama Buku
              </p>
              <p class="d-block my-2 font-weight-bold text-uppercase text-xs">
                Jumlah
              </p>
            </div>
            <div v-show="isShow">
              <div
                v-if="loadingCircle"
                class="text-center py-4"
              >
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
              <div
                v-for="book in books.slice(0, 6)"
                v-else
                :key="book.library.uuid"
                class="d-flex justify-space-between align-center mt-3 mb-5"
              >
                <div class="d-flex justify-start align-center">
                  <p class="mb-0 font-weight-medium black--text">
                    {{ book.library.name }}
                  </p>
                </div>
                <p class="mb-0">
                  {{ book.total }}
                </p>
              </div>
            </div>
            <div v-show="isShowAll">
              <div
                v-if="loadingCircle"
                class="text-center py-4"
              >
                <v-progress-circular
                  indeterminate
                  :color="randomColor()"
                ></v-progress-circular>
              </div>
              <div
                v-for="book in books"
                v-else
                :key="book.library.uuid"
                class="d-flex justify-space-between align-center mt-3 mb-5"
              >
                <div class="d-flex justify-start align-center">
                  <p class="mb-0 font-weight-medium black--text">
                    {{ book.library.name }}
                  </p>
                </div>
                <p class="mb-0">
                  {{ book.total }}
                </p>
              </div>
            </div>
            <div>
              <div
                v-if="books.length > 6"
                v-show="isHideButton"
                class="text-center my-2"
              >
                <a
                  class="d-block primary--text"
                  @click="showAll()"
                >Lihat Semua</a>
              </div>
              <div
                v-show="isShowButton"
                class="text-center my-2"
              >
                <a
                  class="d-block primary--text"
                  @click="moreLess()"
                >Lebih Sedikit</a>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="12"
        sm="12"
        cols="12"
      >
        <!-- <v-img src="@/assets/images/demo/user-membaca.png"></v-img> -->
        <v-card class="px-5 py-5">
          <v-card-title class="align-center">
            <span>User Yang Paling Banyak Membaca</span>
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="me-n3 mt-n2"
            >
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div class="d-flex justify-space-between align-center">
              <p class="d-block my-2 font-weight-bold text-uppercase text-xs">
                Nama User
              </p>
              <p class="d-block my-2 font-weight-bold text-uppercase text-xs">
                Jumlah
              </p>
            </div>
            <div v-show="isShow">
              <div
                v-if="loadingCircle"
                class="text-center py-4"
              >
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
              <div
                v-for="user in users.slice(0, 6)"
                v-else
                :key="user.user.uuid"
                class="d-flex justify-space-between align-center mt-3 mb-5"
              >
                <div class="d-flex justify-start align-center">
                  <p class="mb-0 font-weight-medium black--text">
                    {{ user.user.name }}
                  </p>
                </div>
                <p class="mb-0">
                  {{ user.total }}
                </p>
              </div>
            </div>
            <div v-show="isShowAll">
              <div
                v-if="loadingCircle"
                class="text-center py-4"
              >
                <v-progress-circular
                  indeterminate
                  :color="randomColor()"
                ></v-progress-circular>
              </div>
              <div
                v-for="user in users"
                v-else
                :key="user.user.uuid"
                class="d-flex justify-space-between align-center mt-3 mb-5"
              >
                <div class="d-flex justify-start align-center">
                  <p class="mb-0 font-weight-medium black--text">
                    {{ user.user.name }}
                  </p>
                </div>
                <p class="mb-0">
                  {{ user.total }}
                </p>
              </div>
            </div>
            <div>
              <div
                v-if="users.length > 6"
                v-show="isHideButton"
                class="text-center my-2"
              >
                <a
                  class="d-block primary--text"
                  @click="showAll()"
                >Lihat Semua</a>
              </div>
              <div
                v-show="isShowButton"
                class="text-center my-2"
              >
                <a
                  class="d-block primary--text"
                  @click="moreLess()"
                >Lebih Sedikit</a>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import imageSpod from '@/assets/images/spod/spod.svg'
import dateTimeFormat from '@/utils/date/dateTimeFormat'
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountGroup,
  mdiArrowDownThin,
  mdiArrowLeft,
  mdiArrowUpThin,
  mdiClose,
  mdiCodeArray,
  mdiCrop,
  mdiDotsVertical,
  mdiImageMultipleOutline,
  mdiInformation,
  mdiMagnify,
  mdiMapMarker,
  mdiPencilOutline,
  mdiPlus,
  mdiPoll,
  mdiQrcode,
  mdiRefresh,
  mdiTownHall,
} from '@mdi/js'
import 'cropperjs/dist/cropper.css'
// import InfiniteLoading from 'vue-infinite-loading'
// import ConfirmDialog from '../../components/ConfirmDialog.vue'
// import MarkMapBounds from '../components/MarkMapBounds'
// import ModalDialog from '../../components/ModalDialog.vue'
import { kFormatter } from '@core/utils/filter'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'Spod',
  components: {
    // VueCropper,
    // ModalDialog,
    // ConfirmDialog,
    // FileInput,
    // InfiniteLoading,
    // Dayjs,
    // MarkMapBounds,
    VueApexCharts,
  },
  data() {
    return {
      date: new Date(),
      icons: {
        imageSpod,
        mdiArrowLeft,
        mdiPlus,
        mdiImageMultipleOutline,
        mdiDotsVertical,
        mdiInformation,
        mdiRefresh,
        mdiCrop,
        mdiArrowUpThin,
        mdiArrowDownThin,
        mdiTownHall,
        mdiMapMarker,
        mdiCodeArray,
        mdiAccountGroup,
        mdiMagnify,
        mdiPencilOutline,
        mdiQrcode,
        mdiPoll,
        mdiClose,
      },
      instances: [
        {
          icon: mdiTownHall,
          color: '#AC268F',
        },
      ],
      typeArray: {
        company: 'Perusahaan',
        school: 'Sekolah',
        library: 'Perpustakaan',
      },
      chartOptions: {
        colors: ['#AC268F'],
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: '35%',
            startingShape: 'rounded',
            endingShape: 'rounded',
          },
        },
        xaxis: {
          categories: [
            // 'Januari',
            // 'Februari',
            // 'Maret',
            // 'April',
            // 'Mei',
            // 'Juni',
            // 'Juli',
            // 'Agustus',
            // 'September',
            // 'Oktober',
            // 'November',
            // 'Desember',
          ],
          // categories: [],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          convertedCatToNumeric: false,
        },
        yaxis: {
          labels: {
            formatter: value => kFormatter(value, 0),
          },
        },
        grid: {
          strokeDashArray: 10,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        stroke: {
          curve: 'smooth',
          width: 6,
          lineCap: 'round',
          colors: ['#fff'],
        },
        responsive: [
          {
            breakpoint: 1400,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: '50%',
                },
              },
            },
          },
        ],
      },
      chartData: [
        {
          data: [],
        },
      ],
      isShow: true,
      isShowAll: false,
      isHideButton: true,
      isShowButton: false,
      instance: {},
      color: '',
      colorText: '',
      service: 'statisticspod',
      chartYear: [
        { label: '2022', value: 2022 },
        { label: '2023', value: 2023 },
        { label: '2024', value: 2024 },
      ],
      monthArray: {
        1: 'Januari',
        2: 'Februari',
        3: 'Maret',
        4: 'April',
        5: 'Mei',
        6: 'Juni',
        7: 'Juli',
        8: 'Agustus',
        9: 'September',
        10: 'Oktober',
        11: 'November',
        12: 'Desember',
      },
      thisMonth: (new Date().getMonth() + 1).toString().padStart(2, '0'),
      thisYear: new Date().getFullYear(),
      headers: [
        { text: 'No.', value: 'index' },
        { text: 'Spod ID', value: 'spod_id' },
        { text: 'Nama Spod', value: 'spod_name' },
        { text: 'Latitude', value: 'latitude' },
        { text: 'Longitude', value: 'longitude' },
        { text: 'Jumlah Pengunjung', value: 'statistics.total_history' },
      ],
      spods: [],
      serviceSpod: 'spod',
      instansi: '',
      typeInstance: '',
      books: [],
      users: [],
      error: false,
      isLoadingCard: true,
      isLoadingButton: false,
      isLoadingInstance: false,
      loadingCircle: true,
      noAddButton: true,
      noSearch: true,
      filterLibrary: true,
      page: 1,
      totalPages: 1,
      totalItems: 1,
      itemsPerPage: 10,
      isLoadingData: true,
      instanceUuid: this.$route.params.instance_uuid,
      spod_id: this.$route.params.spod_id,
    }
  },
  watch: {
    page: {
      handler() {
        //   this.listSpod({ company_uuid: this.instanceUuid })
      },
    },
  },
  created() {
    //   this.listSpod()
    this.getDataCount({ company_uuid: this.instanceUuid, spod_id: this.spod_id })
  },
  mounted() {
    this.getInstance(this.instanceUuid)
    this.$refs.chart.refresh()
  },
  methods: {
    showAll() {
      this.isShow = false
      this.isShowAll = true
      this.isHideButton = false
      this.isShowButton = true
    },
    moreLess() {
      this.isHideButton = true
      this.isShowButton = false
      this.isShow = true
      this.isShowAll = false
    },
    randomColor(type) {
      if (type === 'ebook') {
        this.color = 'warning'
      } else if (type === 'audio') {
        this.color = 'success'
      } else {
        this.color = 'error'
      }

      return this.color
    },
    randomTextColor(type) {
      if (type === 'ebook') {
        this.colorText = 'warning--text'
      } else if (type === 'audio') {
        this.colorText = 'success--text'
      } else {
        this.colorText = 'error--text'
      }

      return this.colorText
    },
    getMonthName(date) {
      const options = { month: 'long' }

      return new Intl.DateTimeFormat('id-ID', options).format(date)
    },
    async getInstance(uuid) {
      await this.$services.ApiServices.get('instance', uuid).then(
        ({ data }) => {
          this.instansi = data.data
          this.typeInstance = this.typeArray[data.data.company_types_uuid.type]
        },
      )
    },

    async getDataCount(params) {
      this.loadingCircle = true
      const parYear = (`${this.thisYear}-${this.thisMonth}`)

      await this.$services.ApiServices.list(this.service, { ...params, year: parYear }).then(({ data }) => {
        this.instance.total_ratio_visitors_month = data.total_visitor.percentage_difference
        this.instance.total_visitors_month = data.total_visitor.total_visitor_this_month
        this.books = data.most_borrowed_book
        this.users = data.most_user_checkin
        this.cat = []
        // const act = data.user_activities
        const chartData = [
          {
            data: [],
          },
        ]
        this.cat = data.total_book_borrowed.map(el => (this.monthArray[el.month]))
        chartData[0].data = data.total_book_borrowed.map(el => (el.total))
        this.chartOptions.xaxis.categories = this.cat
        this.chartData = chartData
        this.$refs.chart.refresh()
      })

      this.loadingCircle = false
    },

    handleSelectChange(event) {
      console.log(event)
      this.loadingCircle = true
      const parYear = (`${event}-${this.thisMonth}`)

      this.$services.ApiServices.list(this.service, { company_uuid: this.instanceUuid, year: parYear, spod_id: this.spod_id }).then(({ data }) => {
        // this.instance.total_ratio_visitors_month = data.total_visitor.percentage_difference
        // this.instance.total_visitors_month = data.total_visitor.total_visitor_this_month
        // this.books = data.most_borrowed_book
        // this.users = data.most_user_checkin
        this.cat = []
        const chartData = [
          {
            data: [],
          },
        ]
        this.cat = data.total_book_borrowed.map(el => (this.monthArray[el.month]))
        chartData[0].data = data.total_book_borrowed.map(el => (el.total))
        this.chartOptions.xaxis.categories = this.cat
        this.chartData = chartData
        this.$refs.chart.refresh()
      })
      this.loadingCircle = false
    },

    paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },

    dateFormat(date) {
      dateTimeFormat(date, '-', false, false)
    },
    returnBack() {
      this.$router.back()
    },
  },
}
</script>

  <style>
  .map {
    height: 400px;
    width: 100%;
  }
  .alert > .v-alert__wrapper > .v-alert__content {
    display: flex;
    align-items: center;
  }
  .text-delete {
    color: #e30000 !important;
  }
  .theme--light.v-skeleton-loader .v-skeleton-loader__image {
    height: 307px;
  }
  .loading-spiral {
    border: 3px solid #777 !important;
    border-right-color: transparent !important;
    -webkit-animation: loading-rotating-data-v-46b20d22 linear .85s infinite !important;
    animation: loading-rotating-data-v-46b20d22 linear .85s infinite !important;
  }

  .btn-reset,
  .btn-crop {
    width: 155px;
  }

  .btn-tools {
    height: 30px !important;
    width: 30px !important;
    border-radius: 50% !important;
    min-width: 0 !important;
    padding: 0 !important;
  }
  .image-noaktif{
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  </style>
